import React from 'react'
import styles from './Main.module.scss'
import Layout from "../Layout/layout"
import SEO from "../seo"
//import AsideRight from '../Asides/Aside_right/Aside_right'
import ADS from '../Asides/ADS/Ads_left';
import MainItem from './MainItem/MainItem';

const Main = ({posts}) => {
    let allPosts = [];

    for (let subjPost of posts) {
      subjPost.edges.map(post => allPosts.push(post))
    }

    const sortedPosts = allPosts.sort((a, b) => new Date(b.node.date) - new Date(a.node.date));


    return (
        <Layout>
            <ADS />
            <SEO title='{ Космический фронтенд }' description='Блог о фронтэнд разработке Стаса Багрецова' />
            <main className={styles.main}>
                {sortedPosts.map((post, i) => (
                    <MainItem key={i+34} postData={post.node} />
                ))}
            </main>
        </Layout>
    )
}

export default Main;