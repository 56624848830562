import React  from "react"

import { graphql, StaticQuery } from 'gatsby';
import Main from '../components/Main/Main';

const IndexPage = () => {
  return(
    <StaticQuery query={graphql`
      {
              allWordpressWpJs{
                edges{
                  node{
                    slug
                    content
                    date
                    title
                    id
                    excerpt
                    type
                    featured_media{
                      source_url
                    }
                  }
                }
              }

              allWordpressWpHtmlCss{
                edges{
                  node{
                    slug
                    content
                    date
                    title
                    id
                    excerpt
                    type
                    featured_media{
                      source_url
                    }
                  }
                }
              }
            }
    `} render={({allWordpressWpJs, allWordpressWpHtmlCss}) => {

      

      return (
          <Main posts={[allWordpressWpHtmlCss, allWordpressWpJs]} />
      )
    }} />
  )
};

export default IndexPage
